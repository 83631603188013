import { base_url, toast } from "../../../base";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";

export const GET_ORDER_TYPE = "GET_ORDER_TYPE";
// export const GET_FEEDBACK = "GET_FEEDBACK";

const getOrderType = (orderTypeArr) => ({
    type: GET_ORDER_TYPE,
    payload: orderTypeArr,
});

export function getOrderTypeActions(flagType) {
    let authType = flagType.flag === 1 ? `get_task_type` : `get_order_type`;
    const cacheBuster = new Date().getTime();
    return dispatch => {
        axios
            .get(`${base_url()}/${authType}`, {
                headers: {
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                    'If-Modified-Since': '0',
                },
                withCredentials: true,
                credentials: 'include',
                params: {
                    cache: cacheBuster,
                }
            })
            .then(response => {
                dispatch(getOrderType(response.status === 204 ? [] : response.data.message))
            }).catch(error => {
                if (error.response.status === 404 || error.response.status === 500) {
                    dispatch(getOrderType([]))
                } else if (error.response.status === 401) {
                    toast.error(error.response.data)
                }
            })
    }
}
// const getFeedback = (feedbackArr) => ({
//     type: GET_FEEDBACK,
//     payload: feedbackArr,
// });

// export function getFeedbackActions() {
//     return dispatch => {
//         return new Promise(resolve => {
//             setTimeout(() => {
//                 resolve(
//                     axios
//                         .get(`${base_url()}/get_fb_config`, {
//                             headers: {
//                                 'Cache-Control': 'no-cache',
//                                 'Pragma': 'no-cache',
//                                 'Expires': '0',
//                             }
//                         })
//                         .then(response => {
//                             console.log(response,'resp')
//                             response.data.status ? dispatch(getFeedback(response.data.message)) : dispatch(getFeedback([]))
//                         }).catch(error => console.log(error))
//                 )
//             }, 1000)
//         })
//     }
// }