import React from 'react'
import { Col, Form } from "../../../base";

const MyTextarea = ({ name, label, value, onChange, rows }) => {
    return (<>
        <Col sm="2" style={{ display: 'flex', justifyContent: "end" }}>
            <Form.Label>{label}</Form.Label>
        </Col>
        <Col sm="10" className='mb-2'>
            <textarea type="text" className="form-control" placeholder={label} name={name} onChange={onChange} rows={rows} value={value} ></textarea>
        </Col>
    </>)
}

export default MyTextarea