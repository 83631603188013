import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

const MyAccordion = ({ dataRow, panels, header }) => {
    const useStyles = makeStyles((theme) => ({
        root: { width: '90%' },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightBold,
        },
    }));
    const classes = useStyles();
    const [expanded, setExpanded] = useState(panels);
    const handleChange = (panel) => (event, newExpanded) => setExpanded(newExpanded ? panel : false);
    return (<Accordion expanded={expanded === panels} onChange={handleChange(panels)} className="my-2 text-capitalize" style={{ fontFamily: 'Math' }}>
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" >
            <ExpandMoreIcon />
            <Typography className={classes.heading} >{header} </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ marginTop: -12 }}>
            {dataRow}
        </AccordionDetails>
    </Accordion>)
}

export default MyAccordion