import { base_url, toast, history } from "../../../base";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";

export const GET_SUCCESS_PRODUCT = "GET_SUCCESS_PRODUCT";
export const GET_SUCCESS_ACTIVE_PRODUCT = "GET_SUCCESS_ACTIVE_PRODUCT";
export const INSERT_SUCCESS_MANUFACTURE = "INSERT_SUCCESS_MANUFACTURE";
export const GET_SUCCESS_MANUFACTURE = "GET_SUCCESS_MANUFACTURE";
export const INSERT_SUCCESS_TAX = "INSERT_SUCCESS_TAX";
export const GET_SUCCESS_TAX = "GET_SUCCESS_TAX";

export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_ERROR = 'FETCH_PRODUCTS_ERROR';

const fetchProductsSuccess = (products) => {
    return {
        type: FETCH_PRODUCTS_SUCCESS,
        payload: products
    }
}

const fetchProductsError = (error) => {
    return {
        type: FETCH_PRODUCTS_ERROR,
        payload: error
    }
}

export function getProductActions( query, page, limit) {
    const cacheBuster = new Date().getTime();
    return dispatch => {
        axios
            .get(`${base_url()}/get_product?search_query=${query}&page=${page}&limit=${limit}`, {
                headers: {
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                    'If-Modified-Since': '0',
                },
                withCredentials: true,
                credentials: 'include',
                params: {
                    cache: cacheBuster,
                }
            })
            .then(response => {
                response.data.status ? dispatch(fetchProductsSuccess(response.data)) : dispatch(fetchProductsSuccess([]));
            }).catch((error) => {
                if (error.response.status === 404) {
                    dispatch(fetchProductsSuccess(error.response.data))
                } else if (error.response.status === 500) {
                    dispatch(fetchProductsSuccess({ status: false, message: [] }))
                } else if (error.response.status === 401) {
                    toast.error(error.response.data)
                }
            })
    }
}

// active product
const getActiveProduct = (actProArr) => ({
    type: GET_SUCCESS_ACTIVE_PRODUCT,
    payload: actProArr,
});
export function getActiveProductActions() {
    const cacheBuster = new Date().getTime();
    return dispatch => {
        axios
            .get(`${base_url()}/get_active_product`, {
                headers: {
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                    'If-Modified-Since': '0',
                },
                withCredentials: true,
                credentials: 'include',
                params: {
                    cache: cacheBuster,
                }
            })
            .then(response => {
                response.data.status ? dispatch(getActiveProduct(response.data.message)) : dispatch(getActiveProduct([]))
            }).catch(error => {
                if (error.response.status === 404) {
                    dispatch(getActiveProduct(error.response.data))
                } else if (error.response.status === 500) {
                    dispatch(getActiveProduct({ status: false, message: [] }))
                } else if (error.response.status === 401) {
                    toast.error(error.response.data)
                }
            })
    }
}

// manufacture
export function insertProductManufactureActions(userData, userId) {
    return dispatch => {
        const manuData = {
            owner_id: userId.owner_id,
            manufacture: userData.manufacture,
        }
        axios
            .post(`${base_url()}/api/v1/product/manufacture`, manuData, { withCredentials: true, credentials: 'include' })
            .then(response => {
                if (response.data.status) {
                    dispatch({
                        type: INSERT_SUCCESS_MANUFACTURE,
                        data: response,
                    })
                    toast.success(response.data.message);
                } else {
                    toast.error(response.data.message);
                }
            }).catch(error => {
                toast.error(error.response.data.message)
            })
    }
}

const getManufacture = (manuArr) => ({
    type: GET_SUCCESS_MANUFACTURE,
    payload: manuArr,
});

export function getManufactureActions() {
    const cacheBuster = new Date().getTime();
    return dispatch => {
        axios
            .get(`${base_url()}/get_pro_manu`, {
                headers: {
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                    'If-Modified-Since': '0',
                },
                withCredentials: true,
                credentials: 'include',
                params: {
                    cache: cacheBuster,
                }
            })
            .then(response => {
                dispatch(getManufacture(response.status === 204 ? [] : response.data.message))
            }).catch(error => {
                if (error.response.status === 404 || error.response.status === 500) {
                    dispatch(getManufacture({ status: false, message: [] }))
                } else if (error.response.status === 401) {
                    toast.error(error.response.data)
                }
            })
    }
}

// tax
export function insertProductTaxActions(userData, userId) {
    return dispatch => {
        const manuData = {
            owner_id: userId.owner_id,
            tax: userData.tax,
        }
        axios
            .post(`${base_url()}/api/v1/product/tax`, manuData, { withCredentials: true, credentials: 'include' })
            .then(response => {
                if (response.data.status) {
                    dispatch({
                        type: INSERT_SUCCESS_TAX,
                        data: response,
                    })
                    toast.success(response.data.message);
                } else {
                    toast.error(response.data.message);
                }
            }).catch(error => {
                toast.error(error.response.data.message)
            })
    }
}

const getTax = (taxArr) => ({
    type: GET_SUCCESS_TAX,
    payload: taxArr,
});

export function getTaxActions() {
    const cacheBuster = new Date().getTime();
    return dispatch => {
        axios
            .get(`${base_url()}/get_pro_tax`, {
                headers: {
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                    'If-Modified-Since': '0',
                },
                withCredentials: true,
                credentials: 'include',
                params: {
                    cache: cacheBuster,
                }
            })
            .then(response => {
                dispatch(getTax(response.status === 204 ? [] : response.data.message));
            }).catch(error => {
                if (error.response.status === 404 || error.response.status === 500) {
                    dispatch(getTax({ status: false, message: [] }))
                } else if (error.response.status === 401) {
                    toast.error(error.response.data)
                }
            })
    }
}