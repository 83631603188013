import { base_url, toast } from "../../../base";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";

export const INSERT_SUCCESS_TEAM = "INSERT_SUCCESS_TEAM";
export const GET_SUCCESS_TEAM = "GET_SUCCESS_TEAM";

export function insertTeamActions(userData, userId) {
    return dispatch => {
        const TeamData = {
            owner_id: userId.owner_id,
            team_name: userData.team_name,
            region: userData.region,
            department_id: userData.department_id,
            remark: userData.remark
        }
        axios
            .post(`${base_url()}/insert_team`, TeamData, { withCredentials: true, credentials: 'include' })
            .then(response => {
                if (response.data.status) {
                    dispatch({
                        type: INSERT_SUCCESS_TEAM,
                        data: response,
                    })
                    toast.success(response.data.message);
                } else {
                    toast.error(response.data.message);
                }
            }).catch(error => {
                toast.error(error.response.data.message)
            })
    }
}

const getTeam = (teamArr) => ({
    type: GET_SUCCESS_TEAM,
    payload: teamArr,
});
export function getTeamActions() {
    const cacheBuster = new Date().getTime();
    return dispatch => {
        axios
            .get(`${base_url()}/get_team`, {
                headers: {
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                    'If-Modified-Since': '0',
                },
                withCredentials: true,
                credentials: 'include',
                params: {
                    cache: cacheBuster,
                }
            })
            .then(response => {
                if (response.status === 204) {
                    dispatch(getTeam({ status: false, message: [] }))
                } else {
                    dispatch(getTeam(response.data))
                }
            }).catch(error => {
                if (error.response.status === 404 || error.response.status === 500) {
                    dispatch(getTeam({ status: false, message: [] }))
                } else if (error.response.status === 401) {
                    toast.error(error.response.data)
                }
            })
    }
}