import React, { memo } from 'react'

const MySingleInput = ({ name, label, value, onChange, error, helperText, disabled, showStar, type, flag }) => {
    return <>
        {flag != 1 && <label>{label}{showStar && <span className="text-danger">*</span>}</label>}
        <input type={type || "text"} className="form-control" placeholder={label} disabled={disabled || false} onChange={onChange} value={value || ""} name={name} />
        <small>{error && (<div className="text-danger">{helperText}</div>)}</small>
    </>
}

export default memo(MySingleInput);