import React, { memo } from 'react'
import { Form, Col } from "../../../base";

const MySingleImage = ({ name, label, accept, onChange, showImage }) => {
    return <>
        <Col sm="2" style={{ display: 'flex', justifyContent: "end" }}>
            <Form.Label>{label}</Form.Label>
        </Col>
        <Col sm="10" className='mb-2'>
            <input type='file' name={name} accept={accept} onChange={onChange} className="form-control" />
            {showImage}
        </Col>
    </>
}

export default memo(MySingleImage)