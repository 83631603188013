import { base_url, toast } from "../../../base";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";

export const INSERT_SUCCESS_ACCOUNT = "INSERT_SUCCESS_ACCOUNT";
export const GET_ACCOUNT = "GET_ACCOUNT";
export const GET_ADMIN_ACCOUNT = "GET_ADMIN_ACCOUNT";

export function insertAccountActions(userData, userAccount, userId) {
    return dispatch => {
        const accountData = {
            confirm_account: userData.confirm_account,
            account_name: userData.account_name,
            account_email: userData.account_email ? userData.account_email.replaceAll(" ", ",") : "",
            account_mobile: userData.account_mobile ? userData.account_mobile.replaceAll(" ", ",") : "",
            rating: userData.rating,
            company_name: userData.company_name,
            phone: userData.phone ? userData.phone.replaceAll(" ", ",") : "",
            company_email: userData.company_email ? userData.company_email.replaceAll(" ", ",") : "",
            website: userData.website,
            account_number: userData.account_number,
            account_type: userData.account_type,
            account_industry: userData.account_industry,
            employee: userData.employee,
            annual_revenue: userData.annual_revenue,
            account_gst: userData.account_gst,
            bank_account_name: userData.bank_account_name,
            account_ifsc: userData.account_ifsc,
            bank_name: userData.bank_name,
            branch_name: userData.branch_name,
            billing_street: userData.billing_street,
            billing_city: userData.billing_city,
            billing_state: userData.billing_state,
            billing_code: userData.billing_code,
            billing_country: userData.billing_country,
            shipping_street: userData.shipping_street,
            shipping_city: userData.shipping_city,
            shipping_state: userData.shipping_state,
            shipping_code: userData.shipping_code,
            shipping_country: userData.shipping_country,
            description: userData.description,

            company: userAccount.company,
            contact: userAccount.contact,
            salutation: userAccount.salutation,
            title: userAccount.title,
            business: userAccount.business,
            active_phone: userAccount.active_phone,
            contact_ext: userAccount.contact_ext,
            fax: userAccount.fax,
            mobile: userAccount.mobile,
            alt_phone: userAccount.alt_phone,
            status: userAccount.status,
            alt_nos: userAccount.alt_nos,
            actt: userAccount.actt,
            number: userAccount.number,
            adv: userAccount.adv,
            adv_no: userAccount.adv_no,
            ca: userAccount.ca,
            ca_no: userAccount.ca_no,
            other_add: userAccount.other_add,
            address: userAccount.address,
            city: userAccount.city,
            state: userAccount.state,
            pincode: userAccount.pincode,
            country: userAccount.country,
            referred_by: userAccount.referred_by,
            active_website: userAccount.active_website,
            email: userAccount.email,
            remarks: userAccount.remarks,
            last_remark: userAccount.last_remark,
            last_price: userAccount.last_price,
            nos: userAccount.nos,
            find_as: userAccount.find_as,
            data_with: userAccount.data_with,
            model: userAccount.model,
            version: userAccount.version,
            s_no: userAccount.s_no,
            history: userAccount.history,
            counts: userAccount.counts,
            blocked: userAccount.blocked,
            bls_date: userAccount.bls_date,
            bls_exp: userAccount.bls_exp,
            year_of_expiry: userAccount.year_of_expiry,
            scheme: userAccount.scheme,
            using_as: userAccount.using_as,
            bill_under: userAccount.bill_under,
            installed_on: userAccount.installed_on,
            installed_by: userAccount.installed_by,
            area: userAccount.area,
            billing: userAccount.billing,
            paid_amt: userAccount.paid_amt,
            bill_no: userAccount.bill_no,
            priority: userAccount.priority,
            activation_code: userAccount.activation_code,
            customer_code: userAccount.customer_code,
            aloof_start: userAccount.aloof_start,
            aloof_end: userAccount.aloof_end,
            no_of_user: userAccount.no_of_user,
            name_pwd: userAccount.name_pwd,
            starting_date: userAccount.starting_date,
            busy_key: userAccount.busy_key,
            reg_mail_id: userAccount.reg_mail_id,
            sms_pack: userAccount.sms_pack,
            sms_user_pwd: userAccount.sms_user_pwd,
            whatsapp_no: userAccount.whatsapp_no,
            whatsapp_start_on: userAccount.whatsapp_start_on,
            whatsapp_expiry_date: userAccount.whatsapp_expiry_date,
            qty: userAccount.qty,
            upgrd_date: userAccount.upgrd_date,
            uptd_in_ums: userAccount.uptd_in_ums,
            checked_in_ums: userAccount.checked_in_ums,
            uptd_in: userAccount.uptd_in,
            type: userAccount.type,
            feedback: userAccount.feedback,
            ext_ext: userAccount.ext_ext,
            under_amc: userAccount.under_amc,
            amc_start_on: userAccount.amc_start_on,
            amc_exp_on: userAccount.amc_exp_on,
            mm_yy: userAccount.mm_yy,
            amc_in: userAccount.amc_in,
            month: userAccount.month,
            visit_done: userAccount.visit_done,
            owner_id: userId.owner_id
        }
        axios
            .post(`${base_url()}/insert_account`, accountData)
            .then(response => {
                if (response.data.status) {
                    dispatch({
                        type: INSERT_SUCCESS_ACCOUNT,
                        data: response,
                    })
                    toast.success(response.data.message);
                } else {
                    toast.error(response.data.message);
                }
            }).catch(error => toast.error(error, "Error"))
    }
}

const getAccount = (accArr) => ({
    type: GET_ACCOUNT,
    payload: accArr,
});

export function getAccountActions(authState, setHeaders, authRole) {
    let authAccount = authRole === 1 ? `show_account_admin` : `show_account/${authState}`;
    return dispatch => {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(
                    axios
                        .get(`${base_url()}/${authAccount}`, setHeaders, {
                            headers: {
                                'Cache-Control': 'no-cache',
                                'Pragma': 'no-cache',
                                'Expires': '0',
                            }
                        })
                        .then(response => {
                            response.data.message.length > 0 ? dispatch(getAccount(response.data.message)) : dispatch(getAccount([]))
                        }).catch(error => console.log(error))
                )
            }, 1000)
        })
    }
}

const getAdminAccount = (accAdminArr) => ({
    type: GET_ADMIN_ACCOUNT,
    payload: accAdminArr,
});

export function getAdminAccountActions(setHeaders) {
    return dispatch => {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(
                    axios
                        .get(`${base_url()}/show_account_admin`, setHeaders, {
                            headers: {
                                'Cache-Control': 'no-cache',
                                'Pragma': 'no-cache',
                                'Expires': '0',
                            }
                        })
                        .then(response => {
                            response.data.status ? dispatch(getAdminAccount(response.data.message)) : dispatch(getAdminAccount([]))
                        }).catch(error => console.log(error))
                )
            }, 1000)
        })
    }
}
