import { INSERT_SUCCESS_ACCOUNT, GET_ACCOUNT, GET_ADMIN_ACCOUNT } from "../actions/AccountActions";

const initialState = {
    accArr: [],
    accAdminArr: [],
    success: false,
    loading: true,
};


const AccountReducer = function (state = initialState, action) {

    switch (action.type) {
        case GET_ACCOUNT:
            return {
                ...state,
                accArr: action.payload,
                loading: false
            }

        case GET_ADMIN_ACCOUNT:
            return {
                ...state,
                accAdminArr: action.payload,
                loading: false
            }

        case INSERT_SUCCESS_ACCOUNT:
            return {
                ...state,
                loading: false,
            };

        default:
            return state;
    }

}

export default AccountReducer;