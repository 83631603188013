import { GET_ORDER_TYPE } from "../actions/OrderActions";
import { SIGN_OUT } from "../actions/AuthActions";

const initialState = {
    orderTypeArr: [],
    // feedbackArr: [],
    success: false,
    loading: true,
};


const OrderReducer = function (state = initialState, action) {

    switch (action.type) {
        case GET_ORDER_TYPE:
            return {
                ...state,
                orderTypeArr: action.payload,
                loading: false
            }
        // case GET_FEEDBACK:
        //     return {
        //         ...state,
        //         feedbackArr: action.payload,
        //         loading: false
        //     }
        case SIGN_OUT:
            return {
                success: false,
                loading: false,
            };
        default:
            return state;
    }

}
export default OrderReducer;