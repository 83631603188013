import React, { memo } from 'react'
import { Row, Col, Form } from "../../../base";

const MyInput = ({ name, label, value, onChange, error, helperText, disabled, showStar, type }) => {
    return <Col lg={6} md={6} sm={12}>
        <Row className="d-flex justify-content-end">
            <Col md="4" >
                <Form.Label>{label}{showStar ? <span className="text-danger">*</span> : ""}</Form.Label>
            </Col>
            <Col md="8" className='mb-2'>
                <input type={type || "text"} className="form-control" placeholder={label} disabled={disabled || false} onChange={onChange} value={value} name={name} />
                <small>{error && (<div className="text-danger">{helperText}</div>)}</small>
            </Col>
        </Row>
    </Col>
}

export default memo(MyInput);