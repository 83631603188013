import { base_url, toast } from "../../../base";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";

export const INSERT_SUCCESS_CAMPAIGN = "INSERT_SUCCESS_CAMPAIGN";
export const GET_CAMPAIGNS = "GET_CAMPAIGNS";
export const GET_CAMPAIGN_TYPE = "GET_CAMPAIGN_TYPE";

export function insertCampaginActions(userData, userCheck, userId) {
    return dispatch => {
        const campData = {
            owner_id: userId.owner_id,
            campaign_type_id: userData.campaign_type_id,
            campaign_name: userData.campaign_name,
            campaign_status: userData.campaign_status,
            start_date: userData.start_date,
            end_date: userData.end_date,
            expected_revenue: userData.expected_revenue,
            budgeted_cost: userData.budgeted_cost,
            actual_cost: userData.actual_cost,
            expected_response: userData.expected_response,
            actual_response: userData.actual_response,
            number_sent: userData.number_sent,
            description: userData.description,
            campaign_active: userCheck.campaign_active
        }

        axios
            .post(`${base_url()}/insert_campaign`, campData, { withCredentials: true, credentials: 'include' })
            .then(response => {
                if (response.data.status === true) {
                    dispatch({
                        type: INSERT_SUCCESS_CAMPAIGN,
                        data: response,
                    })
                    toast.success(response.data.message);
                } else {
                    toast.error(response.data.message);
                }

            }).catch(error => toast.error(error, "Error"))
    }
}

const getCampagin = (campArr) => ({
    type: GET_CAMPAIGNS,
    payload: campArr,
});

export function getCampaginActions() {
    return dispatch => {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(
                    axios
                        .get(`${base_url()}/get_campaign`,  {
                            headers: {
                                'Cache-Control': 'no-cache',
                                'Pragma': 'no-cache',
                                'Expires': '0',
                            },
                            withCredentials: true,
                            credentials: 'include',
                            // params: {
                            //     cache: cacheBuster,
                            // }
                        })
                        .then(response => {
                            response.data.status === true ? dispatch(getCampagin(response.data.message)) : dispatch(getCampagin([]))
                        }).catch(error => console.log(error))
                )
            }, 1000)
        })
    }
}

const getCampaginType = (campTypeArr) => ({
    type: GET_CAMPAIGN_TYPE,
    payload: campTypeArr,
});

export function getCampaginTypeActions() {
    return dispatch => {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(
                    axios
                        .get(`${base_url()}/show_campaign_type_active`, {
                            headers: {
                                'Cache-Control': 'no-cache',
                                'Pragma': 'no-cache',
                                'Expires': '0',
                            },
                            withCredentials: true,
                            credentials: 'include',
                            // params: {
                            //     cache: cacheBuster,
                            // }
                        })
                        .then(response => {
                            response.data.status ? dispatch(getCampaginType(response.data.message)) : dispatch(getCampaginType([]))
                        }).catch(error => console.log(error))
                )
            }, 1000)
        })
    }
}