import { base_url, toast } from "../../../base";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";

export const INSERT_SUCCESS_DEPARTMENT = "INSERT_SUCCESS_DEPARTMENT";
export const GET_DEPARTMENTS = "GET_DEPARTMENTS";
export const GET_LEADS = "GET_LEADS";
export const GET_LEAD_ADMIN = "GET_LEAD_ADMIN";
export const GET_OPPORTUNITY = "GET_OPPORTUNITY";

export function insertDepartmentActions(userData, userId) {
    return dispatch => {
        const departmentData = {
            owner_id: userId.owner_id,
            department_name: userData.department_name,
            remark: userData.remark
        }
        axios
            .post(`${base_url()}/insert_department_data`, departmentData, { withCredentials: true, credentials: 'include' })
            .then(response => {
                if (response.data.status) {
                    dispatch({
                        type: INSERT_SUCCESS_DEPARTMENT,
                        data: response,
                    })
                    toast.success(response.data.message);
                } else {
                    toast.error(response.data.message);
                }
            }).catch(error => {
                toast.error(error.response.data.message)
            })
    }
}

const getDepartment = (depArr) => ({
    type: GET_DEPARTMENTS,
    payload: depArr,
});

export function getDepartmentActions() {
    const cacheBuster = new Date().getTime();
    return dispatch => {
        axios
            .get(`${base_url()}/get_active_department`, {
                headers: {
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                    'If-Modified-Since': '0',
                },
                withCredentials: true,
                credentials: 'include',
                params: {
                    cache: cacheBuster,
                }
            })
            .then(response => {
                if (response.status === 204) {
                    dispatch(getDepartment({ status: false, message: [] }))
                } else {
                    dispatch(getDepartment(response.data))
                }
            }).catch(error => {
                if (error.response.status === 404 || error.response.status === 500) {
                    dispatch(getDepartment({ status: false, message: [] }))
                } else if (error.response.status === 401) {
                    toast.error(error.response.data)
                }
            })
    }
}

const getLead = (leadArr) => ({
    type: GET_LEADS,
    payload: leadArr,
});

export function getLeadActions(authState, authRole) {
    let authLead = authRole === 1 ? `get_lead_admin` : `get_lead_user/${authState}`
    const cacheBuster = new Date().getTime();
    return dispatch => {
        axios
            .get(`${base_url()}/${authLead}?search_query=${''}&page=${''}&limit=${''}`, {
                headers: {
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                    'If-Modified-Since': '0',
                },
                withCredentials: true,
                credentials: 'include',
                params: {
                    cache: cacheBuster,
                }
            })
            .then(response => {
                response.data.status ? dispatch(getLead(response.data.message)) : dispatch(getLead([]))
            }).catch(error => {
                if (error.response.status === 404 || error.response.status === 500) {
                    dispatch(getLead({ status: false, message: [] }))
                } else if (error.response.status === 401) {
                    toast.error(error.response.data)
                }
            })
    }
}

const getLeadAdmin = (leadAdminArr) => ({
    type: GET_LEAD_ADMIN,
    payload: leadAdminArr,
});

export function getLeadAdminActions() {
    const cacheBuster = new Date().getTime();
    return dispatch => {
        axios
            .get(`${base_url()}/select_lead_option`, {
                headers: {
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                    'If-Modified-Since': '0',
                },
                withCredentials: true,
                credentials: 'include',
                params: {
                    cache: cacheBuster,
                }
            })
            .then(response => {
                response.data.status ? dispatch(getLeadAdmin(response.data)) : dispatch(getLeadAdmin([]))
            }).catch(error => {
                if (error.response.status === 404 || error.response.status === 500) {
                    dispatch(getLeadAdmin({ status: false, message: [] }))
                } else if (error.response.status === 401) {
                    toast.error(error.response.data)
                }
            })
    }
}

const getOpportunity = (oppArr) => ({
    type: GET_OPPORTUNITY,
    payload: oppArr,
});

export function getOpportunityActions(authState, authRole) {
    // let authOpportunity = authRole === 1 ? `get_opportunity_admin` : `get_opportunity/${authState}`;
    const cacheBuster = new Date().getTime();
    return dispatch => {
        axios
            .get(`${base_url()}/get_opportunity_admin`, {
                headers: {
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                    'If-Modified-Since': '0',
                },
                withCredentials: true,
                credentials: 'include',
                params: {
                    cache: cacheBuster,
                }
            })
            .then(response => {
                response.data.status ? dispatch(getOpportunity(response.data.message)) : dispatch(getOpportunity([]))
            }).catch(error => {
                if (error.response.status === 404 || error.response.status === 500) {
                    dispatch(getOpportunity({ status: false, message: [] }))
                } else if (error.response.status === 401) {
                    toast.error(error.response.data)
                }
            })
    }
}