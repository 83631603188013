import { lazy } from "react";
const Email = lazy(() => import('./email'));
const SocialMedia = lazy(() => import("./social_media"))
const EmailConfiguration = lazy(() => import("./email_configuration"))
const SmsConfiguration = lazy(() => import("./sms_configuration"))
const FullyConfiguration = lazy(() => import("./fully_configuration"))
const EmailTemp = lazy(() => import("./EmailTemplate"))
const Feedback = lazy(() => import("./feedback_configuration"))
const TC = lazy(() => import("./terms-conditions"))

const emailRoutes = [
    {
        exact: true,
        path: "/template",
        component: Email,
    },
    // {
    //     exact: true,
    //     path: "/social_media",
    //     component: SocialMedia,
    // },
    {
        exact: true,
        path: "/email_configuration",
        component: EmailConfiguration,
    },
    {
        exact: true,
        path: "/sms_configuration",
        component: SmsConfiguration,
    },
    {
        exact: true,
        path: "/notification",
        component: FullyConfiguration,
    },
    {
        exact: true,
        path: "/email-template",
        component: EmailTemp,
    },
    {
        exact: true,
        path: "/feedback-configuration",
        component: Feedback,
    },
    {
        exact: true,
        path: "/terms-condition",
        component: TC,
    }
];

export default emailRoutes;
