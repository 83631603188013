import React, { memo } from 'react'
import { Row, Col, Form } from "../../../base";

const MySelect = ({ name, label, value, onChange, error, onBlur, helperText, disabled, showStar, options, valueKey, labelKey }) => {
    return <Col lg={6} md={6} sm={12}>
        <Row className="d-flex justify-content-between">
            <Col md="4">
                <Form.Label>{label}{showStar ? <span className="text-danger">*</span> : ""}</Form.Label>
            </Col>
            <Col md="8" className='mb-2'>
                <select className="form-control select" name={name} onChange={onChange} onBlur={onBlur} value={value} disabled={disabled || false}>
                    <option value={""}>Select</option>
                    {options?.map((option, index) => (<option key={index} value={option[valueKey]}>{option[labelKey]}</option>))}
                    {/* {options.map((val, i) => <option value={val.value} key={i}>{val.label}</option>)} */}
                </select>
                <small>{error && (<div className="text-danger">{helperText}</div>)}</small>
            </Col>
        </Row>
    </Col>
}

export default memo(MySelect)