import React, { memo } from 'react'

const MySingleSelect = ({ name, label, value, onChange, error, helperText, disabled, showStar, options, valueKey, labelKey, flag, showError }) => {
    return <>
        {flag !== 1 && <label>{label}{showStar && <span className="text-danger">*</span>}</label>}
        <select name={name} value={value} onChange={onChange} disabled={disabled || false} className="form-control">
            <option value="">Choose</option>
            {Array.isArray(options) && options?.map((option, index) => (<option key={index} value={option[valueKey]}>{option[labelKey]}</option>))}
        </select>
        {flag !== 1 && < small > {error && (<div className="text-danger">{helperText}</div>)}</small >}
        {showError && < small > {error && (<div className="text-danger">{helperText}</div>)}</small >}
    </>
}

export default memo(MySingleSelect);