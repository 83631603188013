import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const AssignTeam = lazy(() => import('./assignTeam'));

const assignTeamRoutes = [
    {
        exact: true,
        path: "/assignTeams",
        component: AssignTeam,
        auth: authRoles.admin
    },
];

export default assignTeamRoutes;
